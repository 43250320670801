import React, {useEffect, useState} from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import Login from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { AuthContext, ModeContext } from "./context";
import { customFetch } from "./hooks";

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' }
}));

export default function App() {
  const classes = useStyles();
  const [auth, setAuth] = useState(null);
  const [darkMode, setDarkMode] = useState(
      localStorage.getItem("darkMode") === "true"
  );

  useEffect(() => {
    customFetch({
      URL: "/isLoggedIn", isJson: true, responseErrorHandle: false,
      catchHandler: err => {
        console.error(err);
        alert(err.message);
        setAuth(false);
      },
      callback: resp => {
        if (resp) {
          if (resp.error) setAuth(false);
          else if (resp.auth) setAuth(resp.auth);
        }
      }
    });
  }, []);

  console.log("darkMode@@@", darkMode);

  const palletType = darkMode ? "dark" : "light";
  const darkTheme = createMuiTheme({ palette: { type: palletType } });

  return (
      <Router>
        <ModeContext.Provider
            value={{
              darkMode,
              setDarkMode: value => {
                setDarkMode(value);
                localStorage.setItem("darkMode", value);
              }
            }}
        >
          <ThemeProvider theme={darkTheme}>
            <AuthContext.Provider value={{ auth, setAuth }}>
              {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
              <Switch>
                <Route exact path="/">
                  {
                    auth ? <Dashboard />
                        : (auth === false)
                            ? <Login />
                            : (
                                <Backdrop className={classes.backdrop} open={true}>
                                  <CircularProgress color="inherit" />
                                </Backdrop>
                            )
                  }
                </Route>
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
            </AuthContext.Provider>
          </ThemeProvider>
        </ModeContext.Provider>
      </Router>
  );
}

function NoMatch() {
  let location = useLocation();

  return (
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
  );
}