import React, {useContext, useState} from 'react';
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    IconButton, Paper,
    Switch,
    Toolbar,
    Typography
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from "@material-ui/icons/Save";
import RefreshIcon from "@material-ui/icons/Refresh";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {makeStyles} from "@material-ui/core/styles";
import RowItem from "../../RowItem";
import {ModeContext} from "../../../context";
import { AuthContext } from "../../../context";
import {customFetch} from "../../../hooks";

const containerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 };

export default (props) => {
    const { auth, setAuth } = useContext(AuthContext);
    const { darkMode, setDarkMode } = useContext(ModeContext);
    const [MIDS, setMIDS] = useState([]);
    const [V_NUMBERS, setV_NUMBERS] = useState([]);
    const [lastRefresh, setLastRefresh] = useState(new Date().toString());
    const [loading, setLoading] = useState(false);

    const onRefresh = () => {
        setLastRefresh(new Date().toString());
        setLoading(true);
    };

    const deleteRecord = (array, type, value, setter) => {
        setter(
            array.map(record => {
                if (record[type] === value) {
                    return {
                        ...record,
                        toDelete: (!record.toDelete)
                    }
                }
                return record;
            })
        );
    }

    React.useEffect(() => {
        customFetch({
            URL: "/MIDS", isJson: true,
            catchHandler: err => { console.error(err); alert(err.message); },
            callback: result => {
                if (result) {
                    setMIDS(result);
                }
            }
        });

        customFetch({
            URL: "/V_NUMBERS", isJson: true,
            catchHandler: err => { console.error(err); alert(err.message); },
            callback: result => {
                if (result) {
                    setV_NUMBERS(result);
                }
            }
        });

        setLoading(false);
    }, [lastRefresh])

    return (
        <>
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large" edge="start" color="inherit"
                        aria-label="menu" sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div style={containerStyle}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            BILT
                        </Typography>

                        <div>
                            <FormControlLabel label="Dark mode"
                              control={
                                  <Switch name="mode" color="secondary" checked={darkMode}
                                      onChange={() => setDarkMode(!darkMode)}
                                  />
                              }
                            />

                            <IconButton
                                variant="contained" style={{ color: 'white' }} aria-label="refresh"
                                title={`Last refresh: ${lastRefresh}`}
                                onClick={onRefresh}
                            >
                                {
                                    loading ? <CircularProgress size={20} style={{ color: 'white' }} />
                                        : <RefreshIcon />
                                }
                            </IconButton>

                            <IconButton edge="end" aria-label="Log out" color="secondary" title="Log out"
                                onClick={() => {
                                    const cond = window.confirm("Are you sure you want to logout?");
                                    if (cond)
                                        customFetch({
                                            URL: "/logout",
                                            callback: res => {
                                                if (res.status !== 200) alert("Failed to logout " + res.statusText);
                                                else setAuth(false);
                                            }
                                        });
                                }}
                            >
                                <ExitToAppIcon/>
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
        <Paper style={{ height: '70vh', ...boxStyle }}>
            <Paper style={IdBoxStyle}>
                <div style={{ padding: '1.5rem' }}>
                    <strong> MIDs ({ MIDS.length }): </strong>
                    <hr />
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            color="primary" aria-label="add" variant="contained"
                            onClick={() => {
                                const newMID = prompt("Enter MID to be added:");
                                if (!isNaN(newMID))
                                    setMIDS([ ...MIDS, { MID: newMID, toAdd: true } ])
                            }}
                        >
                            <AddIcon />
                        </Button>
                    </div>
                    {
                        MIDS.map((record) => {
                            return (
                                <RowItem
                                    key={record._id} value="MID" record={record}
                                    onDelete={value => deleteRecord(MIDS, "MID", value, setMIDS)}
                                />
                            );
                        })
                    }
                </div>
            </Paper>

            <Paper style={IdBoxStyle}>
                <div style={{ padding: '1rem' }}>
                    <strong> V_NUMBERs ({V_NUMBERS.length}):  </strong>
                    <hr />
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            color="primary" aria-label="add" variant="contained"
                            onClick={() => {
                                const newV_NUMBER = prompt("Enter V_NUMBER to be added:");
                                if (!isNaN(newV_NUMBER))
                                    setV_NUMBERS([ ...V_NUMBERS, { V_NUMBER: newV_NUMBER, toAdd: true } ])
                            }}
                        >
                            <AddIcon />
                        </Button>
                    </div>
                    {
                        V_NUMBERS.map((record) => {
                            return (
                                <RowItem
                                    key={record._id} value="V_NUMBER" record={record}
                                    onDelete={value => deleteRecord(V_NUMBERS, "V_NUMBER", value, setV_NUMBERS)}
                                />
                            );
                        })
                    }
                </div>
            </Paper>
        </Paper>
        <Paper style={{ ...containerStyle, border: '2px solid black', padding: '1rem', margin: '1rem' }}>
            <div>
            Changes:
            <p>
                Adding these MIDS:
                {
                    MIDS.filter(MID => !MID._id && !MID.toDelete)
                        .map(record => (
                            <p>
                                {record.MID},
                            </p>
                        ))
                }
            </p>
            <hr />
            <p>
                Adding these V_NUMBERS:
                {
                    V_NUMBERS.filter(V_NUMBER => !V_NUMBER._id && !V_NUMBER.toDelete)
                        .map(record => (
                            <p>
                                {record.V_NUMBER},
                            </p>
                        ))
                }
            </p>
            <hr />
            <p>
                Removing these MIDS:
                {
                    MIDS.filter(MID => MID._id && MID.toDelete)
                        .map(record => (
                            <p>
                                {record.MID},
                            </p>
                        ))
                }
            </p>
            <hr />
            <p>
                Removing these V_NUMBERS:
                {
                    V_NUMBERS.filter(V_NUMBER => V_NUMBER._id && V_NUMBER.toDelete)
                        .map(record => (
                            <p>
                                {record.V_NUMBER},
                            </p>
                        ))
                }
            </p>
            </div>
            <div>
            <Button color="primary" variant="contained"
                onClick={() => {
                    const mids = {
                        add: MIDS
                            .filter(MID => !MID._id && !MID.toDelete)
                            .map(({ MID }) => ({ MID })),
                        remove: MIDS
                            .filter(MID => MID._id && MID.toDelete)
                            .map(({ MID, _id }) => ({ MID, _id })),
                    };
                    const vnumbers = {
                        add: V_NUMBERS
                            .filter(V_NUMBER => !V_NUMBER._id && !V_NUMBER.toDelete)
                            .map(({ V_NUMBER }) => ({ V_NUMBER })),
                        remove: V_NUMBERS
                            .filter(V_NUMBER => V_NUMBER._id && V_NUMBER.toDelete)
                            .map(({ V_NUMBER, _id }) => ({ V_NUMBER, _id }))
                    };

                    console.log("mids", mids);
                    console.log("vnumbers", vnumbers);

                    if (
                        mids.add.length || mids.remove.length
                        || vnumbers.add.length || vnumbers.remove.length
                    ) {
                        customFetch({
                            URL: `/saveChanges`,
                            isJson: true, type: "POST",
                            POST_API_Input: { MIDS: mids, V_NUMBERS: vnumbers },
                            callback: resp => {
                                if (resp && resp.error) alert(resp.error);
                                else {
                                    alert("Changes Saved!");
                                    setLastRefresh(new Date().toString())
                                }
                            }
                        });
                    }
                }}
            >
                <SaveIcon />
                Click to confirm changes and submit
            </Button>
            </div>
        </Paper>
        </>
    );
};

const boxStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-around'
};

const IdBoxStyle = {
    width: '45%',
    height: '100%',
    overflow: 'scroll'
}