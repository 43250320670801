import { BASE_URL } from "../util";

export const customFetch = ({
                                URL, isJson = false, credentials = true, type = "GET",
                                callback, options = {}, POST_API_Input = {}, responseErrorHandle = true,
                                catchHandler = err => {
                                    console.error(err);
                                    alert(err.message);
                                },
                            }) => {
    const optionsParam = { ...options, ...(credentials ? { credentials: 'include' }: {} )};
    const url = `${BASE_URL}${URL}`;

    const fetchErrorHandler = response => {
        if (responseErrorHandle && response.error) {
            alert(response.error);
            window.location.reload();
        }
        else callback(response);
    };

    switch (type) {
        case "GET":
            if (isJson)
                fetch(url, optionsParam)
                    .then(response => response.text())
                    .then(text => {
                        try { return JSON.parse(text); }
                        catch(err) {
                            alert("Error fetching (no JSON response)" + `${BASE_URL}${URL}`);
                            throw err;
                        }
                    })
                    .then(fetchErrorHandler)
                    .catch(catchHandler);
            else fetch(url, optionsParam)
                .then(fetchErrorHandler)
                .catch(catchHandler);
            break;
        case "POST":
            if (isJson)
                fetch(url,
                    {
                        ...optionsParam, method: 'POST',
                        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
                        body: JSON.stringify(POST_API_Input)
                    })
                    .then(response => response.text())
                    .then(text => {
                        try { return JSON.parse(text); }
                        catch(err) {
                            alert("Error fetching (no JSON response)" + `${BASE_URL}${URL}`);
                            throw err;
                        }
                    })
                    .then(fetchErrorHandler)
                    .catch(catchHandler);
            else
                fetch(url,
                    {
                        ...optionsParam, method: 'POST',
                        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
                        body: JSON.stringify(POST_API_Input)
                    })
                    .then(fetchErrorHandler)
                    .catch(catchHandler);
            break;
    }
};