import React, {useContext, useState} from 'react';
import {
    Button, Container, CssBaseline, IconButton, InputAdornment, makeStyles, Paper,
    TextField, Typography
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {AuthContext} from "../../context";
import {customFetch} from "../../hooks";

const useStyles = makeStyles((theme) => ({
    paper: {
        minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
    },
    avatar: { margin: theme.spacing(1), backgroundColor: theme.palette.secondary.main },
    form: { width: '100%', marginTop: theme.spacing(1) },
    submit: { margin: theme.spacing(3, 0, 2) }
}));

const Login = props => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { auth, setAuth } = useContext(AuthContext);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const onSubmitHandler = () => {
        if(email && password) {
            customFetch({
                URL: `/login`, isJson: true, type: "POST", POST_API_Input: { email, password },
                callback: resp => {
                    if (resp && resp.error) alert(resp.error);
                    else if (resp && resp.auth) setAuth(true);
                }
            });
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5"> Sign in </Typography>
                <form className={classes.form} noValidate>
                    <Paper style={{ padding: "0.75rem" }}>
                        <TextField color="secondary"
                                   variant="outlined" margin="normal" required fullWidth id="email"
                                   label="Email Address" name="email" autoComplete="email" autoFocus
                                   value={email} onChange={({ target: { value } }) => setEmail(value)}
                        />
                        <TextField color="secondary"
                                   variant="outlined" margin="normal" required fullWidth name="password"
                                   label="Password" type={showPassword ? "text" : "password"} id="password" autoComplete="current-password"
                                   value={password} onChange={({ target: { value } }) => setPassword(value)}
                                   InputProps={{
                                       endAdornment: (
                                           <InputAdornment position="end">
                                               <IconButton
                                                   aria-label="toggle password visibility" onClick={handleClickShowPassword}
                                               >
                                                   {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon /> }
                                               </IconButton>
                                           </InputAdornment>
                                       )
                                   }}
                                   onKeyDown={(e) => {
                                       if (e.key === 'Enter' && email && password) onSubmitHandler();
                                   }}
                        />
                        <Button fullWidth variant="contained" color="primary"
                                className={classes.submit} onClick={onSubmitHandler}
                        >
                            Sign In
                        </Button>
                    </Paper>
                </form>
            </div>
        </Container>
    );
};

export default Login;