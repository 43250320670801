import Fab from "@material-ui/core/Fab";
import RemoveIcon from "@material-ui/icons/Remove";

const RowItem = ({ value, key, record, onDelete }) => {
    return (
        <div style={containerStyle} key={key}>
            <Fab
                color="secondary" aria-label="add"
                style={{ width: '2.5rem', height: '2.5rem' }}
                onClick={() => onDelete(record[value])}
            >
                <RemoveIcon />
            </Fab>
            <p style={record.toDelete ? { textDecoration: 'line-through'} : {}}>
                {record[value]} {record.toAdd ? '+': ''}
            </p>
        </div>
    );
};

const containerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

export default RowItem;